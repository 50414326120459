.form-control{
	border-radius: 0;
	//height:44px;
	letter-spacing: .05em;
  box-shadow: none;
	border: 1px solid #ccc;
}
input[type='text'].form-control{text-align: right;}
input[type='text'].form-control.natural{text-align: left;}
.input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group{
	//height: 44px;
  padding:12px;
  &.uploader{
    padding: 6px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 0;
    border-right: 0;
  }
	
}
.input-group-addon:first-child, .input-group-addon:last-child{border-radius: 0;}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{cursor:not-allowed;}
label {
  letter-spacing: .03em;
  color: #053359;
}

select.form-control {
  -webkit-border-radius: 0;  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -moz-border-radius: 0;  /* Firefox 1-3.6 */
  border-radius: 0;  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
}
.has-success.has-feedback.form-control{border-color:$brand-success;}
label.has-success.has-feedback{color:$brand-success;}
.has-error.has-feedback.form-control{border-color:$brand-danger;}
label.has-error.has-feedback{color:$brand-danger;}
.help-block{color:#555;}
.help-block-danger{ color:#e51c23; font-weight: 700;}

// Multiselect styles

.combobox {
  width: auto;
  display: inline-block;
  position: relative;
}

#textbox {
  font-size: 1em;
  color: #767676;
  background-color: #fff;
  font-weight: bold;
  width: 220px;
  outline: none;
  padding: 15px 40px 15px 20px;
  margin: 0;
  border: 1px solid #999;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#textbox.selections {color: #069;}
.combobox button.toggle:focus {
  outline: none;
  box-shadow: none;
}
.combobox button.toggle {
  background-color: #fff;
  padding: 0;
  border-top: 8px solid #6c6c6c;
  border-right: 8px solid transparent;
  border-bottom: none;
  border-left: 8px solid transparent;
  position: absolute;
  right: 10px;
  top: 21px;
  width: 16px;
}

.combobox button.clear {
  display: none;
  position: absolute;
  top: 13px;
  right: 4px;
  width: 31px;
  height: 26px;
  background-color: #fff;
  border: none;
}

.combobox button.clear::before {
  content: '×';
  position: absolute;
  top: 2px;
  left: 6px;
  z-index: 1;
  background-color: #6c6c6c;
  border-radius: 100%;
  font-size: 19px;
  color: #fff;
  line-height: 1;
  width: 21px;
  height: 21px;
}

.combobox button.clear:focus::before,
.combobox button.clear:hover::before {background-color: #069;}

.combobox ul {
  display: none;
  list-style: none;
  background-color: #fff;
  font-size: 1em;
  width: auto;
  margin: 0;
  padding: 10px 0;
  border: 1px solid #999;
}

.combobox li {
  font-weight: bold;
  padding: 5px 0 5px 50px;
  position: relative;
}

.combobox .activedescendant {
  background-color: #ddd;
  color: #069;
}

.combobox li[aria-selected="true"] {color: #000;}

.combobox li[aria-selected="true"].activedescendant {color: #069;}

.combobox li span.check::before {
  position: absolute;
  content: ' ';
  line-height: 1;
  text-align: center;
  left: 20px;
  top: 10px;
  border-radius: 3px;
  width: 15px;
  height:15px;
  margin-right: 10px;
  border: 1px solid #7B8E9B;
  background: #f9f9f9;
}

.combobox li[aria-selected="true"] span.check::before {content: "✓";}

// new select box sept 2018
.acl-select  {
	display:inline-block;
	vertical-align:middle;
	position:relative;
	width:100%;
}
.acl-select select {
	line-height:normal;
	height:38px;
	-webkit-appearance:none;
	-moz-appearance:none;
	-ms-appearance:none;
	appearance:none;
	border-radius:6px;
	display:block;
	width:100%;
	border:1px solid #000;
	background: #f5fbff;
	color:#37383F;
	padding:8px 20px 7px 10px;
	margin:0;
	line-height:normal;
	-webkit-border-radius:2px;
	-moz-border-radius:2px;
  border-radius:2px;
  z-index: 99 !important;
}
.acl-select select:focus {
	background:#f9f9f9;
	outline:none;
	box-shadow:0 0 10px rgba(0, 0, 0, 0.3), inset 0 0 7px rgba(100,100,100,0.3);
	border-color:#00c0f3;
}
.acl-select ::-ms-expand {
 display:none;
}
.acl-select:after{
	content:"";
	position:absolute;
	z-index:2;
	right:8px;
	top:50%;
	margin-top:-3px;
	height:0;
	width:0;
	border-top:6px solid #094375;
	border-left:6px solid transparent;
	border-right:6px solid transparent;
	pointer-events:none;
}


