.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.btn-default.single {
    border: 0;
    .caret {
        display: none;
    }
}

.btn-default {
    border: 1px solid #053359;
}

.btn {
    border-radius: 0px;
    font-size: 15px;
    padding: 15px;
    text-transform: inherit;
    letter-spacing: .1em;
    position: relative;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
}

.btn-sm {
    border-radius: 0px;
    border-style: none;
}

.btn-inverted {
    background: white;
    color: #053359;
    border-color: #053359;
    &:hover {
        -webkit-transform: inherit;
        transform: inherit;
    }
}

.btn-primary:disabled {
    color: black;
}

.btn-primary:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #053359;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.btn.btn-primary:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.btn.btn-success:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #336633;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.btn.btn-success:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.btn.btn-info:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #1d7892;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.btn.btn-info:focus:before,
.btn.btn-info:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.btn.btn-warning:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #b46a00;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.btn-info {
    color: black;
}

.btn.btn-warning:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.btn.btn-danger:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #961410;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.btn.btn-danger:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

button.upload {
    padding: 10px;
    border: 1px solid $brand-primary;
}

button.upload[aria-expanded="false"]>span,
button.upload>span {
    transform: rotate(180deg);
}

button.upload[aria-expanded="true"]>span {
    transform: rotate(0deg);
}

button.upload>span {
    display: inline-block;
    vertical-align: middle;
    height: 32px;
    width: 32px;
    transition: transform 1s;
    font-size: 24px;
}

// White button with blue border and text and error appear as red
.btn-action {
    @include button-variant($btn-action-color, $btn-action-bg, $btn-action-border);
}

.btn-sm.btn-action {
    border-width: 1px; 
    border-style: solid;
}

.btn-export {
    background: white;
    color: #063557;
    border-color: #063557;
    padding: .5em;
    &:active,
    &:focus {
        background: #033458;
        color: white;
    }
}

.divider{
    width:20px;
    height:auto;
    display:inline-block;
}

// disabled buttons across the site
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    background: #BCBCBC;
    border-color: #BCBCBC;
    &:before {
        transition-duration: 0s;
    }
}

.btn-secondary {
    background: white;
    color: #063557;
    border-color: #063557;
}