.alert.icon {
  min-height:75px;
  padding:0px;
  i{
    display:inline-block;
    height:75px;
    width:75px;
    color: $text-dark-gray-color;
    font-size: 40px;
    text-align: center;
    vertical-align:middle;
    padding-top: 15px;
  }
}

.alert-info.clock{
  min-height:75px;
  background:#EEEEEE;
  border-color:#CCCCCC;
  border-radius:0;
  padding: 0;
  i{
    display:inline-block;
    height:75px;
    width:75px;
    color: $text-dark-gray-color;
    font-size: 40px;
    text-align: center;
    vertical-align:middle;
    padding-top: 15px;
  }
  strong{color:#063557;font-size: 18px;}
  color:#000000;
}
.not-started, .in-progress, .in-progress-error, .validated-w-warnings, .validated, .submitted, .approved, .returned, .locked, .review {
  font-size: 14px;
  i{font-size:20px;vertical-align:middle;}
}
.in-progress, .returned{color:#48226B;}
.in-progress-error{color:#983632;}
.validated-w-warnings{color:#A86416;}
.validated, .approved{color:#2A6035;}
.submitted, .review{color:#4A4A4A;}
.not-started, .locked{color:#4A4A4A;}
