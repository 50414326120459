// State User specific styles
.flags{
    border:1px solid #eee;
    box-shadow: 10px 10px 13px -9px rgba(0,0,0,0.22);
    height: 95px;
}
.flags.t3{height: 154px;}
.btn.ico{
    border:1px solid #063557;
    border-radius:0;
    color:#063557;
    font-size: 16px;
    margin-bottom: 4px;
    min-width: 275px;
    padding:10px;
    text-align:left;
    text-transform: uppercase;
    i{font-size:20px;margin-right:5px;}
    &:hover{
    i{color:#377BB5;}
    }
  }