.alert{
  border-radius: 0;
  font-size: 16px;
}
.alert-success{background-color: #F1F8E9; border-color:#33691E; color:#000000;}
.alert-info{color:#000000;}
.alert-status{background-color: $gray-lighter;border-color:$gray-light;} //acl specific
.alert-danger{
  background-color: #FDDDE0;
  border-color:#911927;
  color:#000000;
  i, strong{color:#911927;}
}
.alert-warning, .has-warning {
    @include form-control-validation($state-warning-text, $state-warning-text, $state-warning-bg);
    border-color:#a47e3c;
  }
.toast-warning {
  background-color: #cfc61f!important;
}
