.panel{border-radius: 0;}
.min-head-panel{
    .panel-heading{min-height: 65px;}
}
.panel-primary{
    background:#09528F;
    color:white;
    min-height: 185px;
    hr{border:1px solid #F9BF3B;margin:.5em;}
    a{color: #FFFFFF}
  }

.panel-elementExplanation {
  background: #09528F;
  color: white;
}

.panel-heading.panel-heading-primary {
  background: #09528F;
  color: white;
}
.panel-default{
    background: #FAFAFA;
    h3{
        border-bottom:1px solid #ddd;
        color:#063557;
        font-size:18px;
        font-weight: bold;
        margin: 0 0 10px 0;
        padding-top:.5em;
        padding-bottom:.5em;
        }
}
.accordionGroup .panel-default>.panel-heading{cursor:pointer;}
.flex-list.panel-group{
    .panel{
        border-radius: 0;
        box-shadow: 0 1px 2px rgba(0,0,0,0.15);
        transition: box-shadow 0.3s ease-in-out;
        transition-delay: .2s;
    }
    .panel:hover{
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
        transform:scaleX(1.03);
        transform:scaleY(1.03);
        a{color: white;text-decoration: none;}
    }

}
