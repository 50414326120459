$gray-lightest: #fafafa;
$gray-light: #DDDDDD;
.panel-primary {
    &.ref {
    }
    background:#09528F;
    color:white;
    hr {
        border: 1px solid #F9BF3B;
    }
    .panel-body {
        min-height: 200px;
        @media (max-width: 991px) {
            min-height: auto;
        }
        p.description,
        .files a {
            font-size: 10px;
            line-height: 1.7em;
        }
    }
    &:hover {
        background-color: #4A4A4A;
        border-color: #4A4A4A;
        .files {
            background-color: #5C5C5C;
        }
    }
    .files {
        i {
            font-size: 14px;
            margin-right: 5px;
        }
        background:#3D6298;
        margin:0;
        padding:10px 15px;
        bottom:0;
        width:100%;
        font-size: 10px;
        line-height: 1.7em;
        li {
            padding:0;
        }
        a {
            color: white;
        }
    }

}

.td-none {
    text-decoration: none !important;
}

.panel-primary-disabled {
    background:white;
    border-color:#333333;
    strong, small { color: #4A4A4A; }
    .panel-body{ flex-grow:100; }
    hr { border: 1px solid #333333; }
    &:hover {
        cursor: default;
    }
}

.panel-primary-inverted{
    &.files{
        @extend .p-0;
    }
    background:white;
    border-color:#09528F;
    strong{color: #09528F;}
    color:black;
    text-decoration: none;
    .panel-body{flex-grow:100;}
    &:hover, &:focus {
        background-color: #09528F;
        border-color: #4A4A4A;
        text-decoration:none;
        color:white;
        strong{color:white;}
        a{color:white}
        .files {
            background-color: #5C5C5C;
        }
    }
    hr {
        border: 1px solid #F9BF3B;
    }
    .files {
        i {
            font-size: 14px;
            margin-right: 5px;
        }
        background:#fafafb;
        margin:0;
        padding:10px 15px;
        bottom:0;
        width:100%;
        font-size: 10px;
        line-height: 1.7em;
        li {
            padding:0;
        }
        a {
            color: black;
        }
    }
    .vidBlock {
        margin: -16px -16px 20px;
        position: relative;
        img {
            width: 100%;
        }
        i {
            color: white;
            text-shadow: 3px 3px 8px #666;
            position: absolute;
            left: 40%;
            top: 12%;
            font-size: 60px;
        }
        @media (max-width: 991px) {
            i {
                top: 30%;
                left: 45%;
            }
        }

        .webinar:focus{
            img {
                border: 10px solid #09528F;
            }
        }
    }

}

.border-gray-light {
    border: 1px solid $gray-light;
}

.border-left-gray-light {
    border-left: 1px solid $gray-light;
}

.date-lg {
    background: #11538D;
    height: 115px;
    margin-right: 1em;
    min-width: 115px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    &.invert {
        background: white;
        border-top: inherit;
        border: 1px solid #11538D;
        color: #11538D;
        span {
            margin: auto 15%;
            &.label {
                background: white;
                border-radius: 0;
                color: #11538D;
                border-top: 2px solid #F8BE49;
            }
        }
    }
    span {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 20%;
        font-size: 20px;
        font-weight: 600;
        margin: auto 15%;
        &.label {
            top: inherit;
            bottom: 0;
            border-radius: 0;
            border-top: 2px solid #F8BE49;
            right: 0;
            left: 0;
            padding: 10px;
            display: block;
            font-size: 12px;
            font-weight: inherit;
            font-size: 40px;
        }
    }
}

.date-info {
    min-width: 35%;
    margin-left: 1em;
    padding-left: 1em;
    border-left: 1px solid $gray-light;
    min-height: 115px;
    i {
        font-weight: 600;
        font-size: 16px;
        margin-right: 5px;
        width: 20px;
        float:left;
    }
    span {
        display: block;
        overflow: hidden;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    li {
        padding: .5em;
        font-size:12px;
    }
}

.contact-info {
    @extend .background-gray-lightest,
    .border-gray-light,
    .p-2;
    hr {
        background: #F9BF3B;
        color: #F9BF3B;
        height: 2px;
        margin: 5px 0;
    }
}
.centralContacts:last-child{
    hr{display:none;}
}
.acl-contacts {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: .8em 0;
    font-size: 12px;
  }

  .col-md-6 {
    margin-bottom: 2em;
  }

  .title {
    position: relative;

    .region {
      text-transform: uppercase;
      font-size: 11px;
    }
  }

  .stateDropdown {
    width: 14.3em;
  }

  i {
    font-weight: 600;
    font-size: 18px;
    margin-right: 5px;
    width: 20px;
    text-align: center;
    vertical-align: middle;
    float: left;
  }

  span {
    display: block;
    overflow: hidden;
  }

  ul:last-child::after {
    border: none;
  }

  .individualContacts {
    display: block;
    position: relative;
    border-bottom: 0px solid #F9BF3B;
    margin-bottom: 1em;
    padding-bottom: 1em;

    &::after {
      content: "";
      position: absolute;
      height: 1px;
      border-bottom: 2px solid #F9BF3B;
      width: 90px;
      bottom: 0;
      left: 0%;
    }
  }
}
