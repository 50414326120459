.navbar {
    margin-bottom: 0;
}

nav#header-navbar .region-navigation {
    width: 90% !important;
    display: table;
    margin: 0 auto;
    padding: 30px;
    max-width: 1170px;
    width: 1170px;
    #block-headerlogo {
        display: table-cell;
        .subtitle {
            display: inline;
        }
        .border-right {
            border-right: #DDDDDD thin solid;
            margin-right: 20px;
            padding-right: 20px;
            float: left;
        }
    }
    #block-topnavigation {
        display: table-cell;
        text-align: right;
        width: auto;
        font-size: 11px;
        position: relative;
        /* mini dropdown in the header  */
        .dropdown {
            position: absolute;
            right: 0;
            z-index: 99;
        }
        button.btn.btn-link {
            color: #3371A0;
            font-size: inherit;
            padding: 0;
            vertical-align: baseline;
        }
    }
}

.navbar-collapse {
    background-color: #09528F;
}

.navbar-collapse .region-navigation-collapsible {
    margin: 0 auto;
    width: 90% !important;
    max-width: 1170px !important;
    li a {
        color: #FFF;
        padding: 2em .75em;
        text-transform: uppercase;
        background-color: #084475;
        border-right: 1px solid #075290;
        min-width: 100px;
        text-align: center;
    }
    li a.active,
    li a.router-link-active,
    li a:hover {
        background-color: #033458;
        color: white;
    }
}

.navbar-header {
    background-color: #FFF;
    float: none;
}

/* Main Nav Menu */

nav#header-navbar .region-navigation #block-topnavigation {
    font-size: 13px;
}

#header-navbar #navbar li a {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
}

#header-navbar #navbar li a:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: #F9BF3B;
    height: 4px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

#header-navbar #navbar li a:hover:before {
    right: 0;
}

.navbar-collapse .region-navigation-collapsible li a {
    padding: 2em 1em;
    letter-spacing: .1em;
    text-align: left;
}

// notification bar
.navbar-secondary {
    min-height: 55px;
    background-color: #D0D0D0;
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 1em;
    span {
        display: inline-block;
        vertical-align: middle;
        padding: .5em 1em .5em 0;
        font-size: 22px;
    }
    .navbar-header {
        background-color: transparent;
    }
}

// Side Navigation
.side-navigation {
    li {
        list-style-type: none;
        cursor: pointer;
        &.title {
            font-size: 20px;
            color: #033458;
            font-weight: 700;
        }
        ul,
        li {
            margin-left: 0;
            padding-left: 0;
            font-size: $font-size-base;
            font-weight: normal;
        }
        ul>li a {
            border: 1px solid $gray-lighter;
            margin-top: .5em;
            margin-bottom: .5em;
            padding: 1em;
            display: flex;
            align-items: center;
            flex-flow: row;
        }
        ul>li a:hover {
            background-color: $gray-lighter;
        }
        ul>li small {
            display: inline-block;
            color: $text-color;
        }
        ul>li .active small {
            color: white;
        }
        ul>li a {
            color: #033458;
        }
        ul>li p {
            width: 95%;
            justify-content: center;
            margin: 0;
        }
        ul>li a.active {
            border-color: #063557;
            background-color: #063557;
            color: white; //empty span is filled to create arrow
            span {
                width: 10%;
                color: white;
            }
        }
        .fa-2x {
            font-size: 1.6em;
        }
    }
    li.subNav {
        border: 1px solid;
        padding: 1em 1em 1em 0;
        border-left: 6px solid;
        strong,
        ul {
            margin-left: 15px;
        }
        li.active a {
            border-left: 1px solid;
            color: black;
            cursor: not-allowed;
        }
    }
}

.panel-default {
    background-color: #eee;
}

.side-navigation li ul>li a {
    text-decoration: none;
    border: 1px solid #ddd;
    background-color: #f6f6f6;
}

.side-navigation li {
    &.leadNav {
        border: 1px solid #ddd;
        padding: 1em;
        background-color: #f6f6f6; // sub nav styles
        &.subActive {
            background-color: #063557;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom-color: #ddd;
            color: white;
            padding: 0;
            li.leadNav.subActive strong{padding:1em;}
            strong {
                display: block;
                padding:1em;
            }
            ul li strong{padding:0;}
            li {
                margin: 0;
                border-right: 1px solid #ddd;
                border-left: 1px solid #ddd;
            }
            li:last-child a {
                border-bottom: 0;
            }
            li>p {
                padding: 0;
            }
            ul li a {
                background: white;
                border: 0;
                border-top: 1px solid #ddd;
                margin-top: 0;
                margin-bottom: 0;
                padding-left: 30px;
            }
            ul li a.active {
                background: #10518D;
                border: 0;
                border-right: 1px solid #10518D;
                border-left: 1px solid #10518D;
                font-weight: normal;
            }
            li.withSub {
                border-left: 0;
                border-right: 0;
            }
            .withSub {
                ul {
                    margin-left: 15px;
                    margin-right: 15px;
                }
                li {
                    border-top: 0;
                    border-right: 0;
                    border-bottom: 1px solid #ddd;
                    border-left: 0;
                }
                li:last-child {
                    border-bottom: 0;
                }
                ul li p {
                    padding-left: 10px;
                }
                a {
                    background: white;
                    font-weight: 600;
                    color: #033458;
                    padding-left: 10px;
                    padding-right: 0;
                    border-bottom: 0;
                }
                a.active {
                    border-left: 0;
                    border-top: 0;
                    font-weight: 600;
                    border-bottom: 0;
                    span {
                        color: #4A4A4A;
                    }
                }
                ul li:first-child a {
                    margin-top: 0;
                }
                ul li a {
                    border-top: 0;
                    border-bottom: 0;
                    background-color: #F5FBFF;
                    color: #000;
                    font-weight: normal;
                    padding-left: 15px;
                }
            }
        }
        &.subActive li.leadNav.subActive {
            background-color: #F5FBFF;
            border-bottom: 0;
            strong {
                padding-left: 30px;
                background: #10518D;
                margin: 0 -1px;
            }
            ul li a.active {
                border-right: 0;
                border-left: 0;
            }
            a {}
        }
    }
}

.disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

/* hide show filter style  */

#filterButton[aria-expanded="true"] {
    .caret {
        transform: rotate(-180deg);
    }
}

/* Accordion Table Navigation */

.collapse.in>th {
    padding-left: 40px;
}

tr.collapse.in,
tr.collapse.in td.background-gray-lighter {
    background-color: #CCC;
    font-weight: normal;
}

.clickable {
    td,
    th {
        position: relative;
    }
    button {
        border: 0;
        background: none;
        right: 0px;
        text-decoration: none;
        display: inline-block;
        text-align: center;
        i {
            width: 20px;
            height: 20px;
        }
    }
    .fa-minus {
        display: none;
    }
}

.clickable.panel-title {
    button {
        position: static;
    }
}

.clickable button[aria-expanded="true"] .fa-minus {
    display: inline;
}

.clickable button[aria-expanded="true"] .fa-plus {
    display: none;
}

.clickable button[aria-expanded="false"] .fa-plus {
    display: inline;
}

.clickable button[aria-expanded="false"] .fa-minus {
    display: none;
}

.cases {
    th[scope="row"] {
        color: inherit;
        font-weight: normal;
        width: 99%;
    }
}

//back to top button
#toTop {
    position: fixed;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    display: none;
    z-index: 3;
}

.profileNav {
  li a:focus {
    outline: 1px dotted;
    background-color: #09528F;
  }
}

//Begin Keyboard shortcut menu
#keyboardShortcutsMenu {
  background-color: #FFFFFF;
  position: absolute;
  z-index: 100;
  outline: dotted #09528F;
  padding-left: 2em;
  padding-bottom: 1em;
}
//End Keyboard shortcut menu
