 .navbar-default {
   border: 0;
  .navbar-nav {
    > li > a {

      &:hover,
      &:focus {
        color: white;
      }
    }
  }
   // Dropdown menu items
 .navbar-nav {
  // Remove background color from open dropdown
  > .open > a {
    &,
    &:hover,
    &:focus {
      background-color: $brand-secondary;
      color: white;
    }
  }
}
 }
 .navbar-nav > li > .dropdown-menu{
  margin-top: -1px; 
  padding: 0;
 }
