/* Administration */

#admin-container  {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

#admin-container  > ol,
#admin-container  > h1 {
  width: 100%;
}

#admin-container > .col-md-6 {
  padding: 0px;
}

#admin-container > .col-md-6 > div {
  height: 100%;
}

#admin-container > .col-md-6:last-child {
  padding-right: 0px;
  padding-left: 15px;
}

#admin-container .big-number-box {
  height: 120px;
  padding-top: 30px;
  border: 1px solid #053359;
  color: #053359
}

#admin-container h2 {
  margin-left: 15px;
}

#admin-container .alert.alert-info {
  margin: 14px 15px 0px;
  display: inline-block;
}

#admin-container .col-md-6 > div > div:last-child {
  margin-left: 15px;
}

#admin-container .fa {
  margin-right: 0px;
  font-size: 2.5em;
}

#admin-container .fa-user:before {
  content: "\f0c0";
}


/* Media Queries */

@media (max-width: 991px) {
  #admin-container > .col-md-6 {
    width: 100%;
  }

  #admin-container > .col-md-6:last-child {
    padding-left: 0px;
    margin-top: 10px;
  }
}