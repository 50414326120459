.table>thead>tr>th {
    border-bottom: 0;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    vertical-align: middle;

}
th{
    small{font-weight:normal;}
}

.table>tbody>tr>th {
  padding: 8px 3px;
//   color: #053359;
}

caption {
  letter-spacing: .08em;
  color:black;
  font-weight:600;
}

fieldset {
    font-weight: bold;
}
.table.thick{
    background:#FAFAFA;
    border:1px solid #DDDDDD;
    th{text-align:left;}
    td, th{padding:.7em;}
    th[scope] {background:#EEEEEE;}
    td{color:#11538D;}
  }
  th.normal{
      color: inherit !important;
      font-weight:normal;
  }
  .table > tbody > tr > th{
      padding:8px;
  }
  .table {
    // Cells
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-top:0;
          border-bottom: 1px solid $table-border-color;
        }
      }
    }
    &.table-no-border > thead > tr > th, &.table-no-border > thead > tr > td, &.table-no-border > tbody > tr > th, &.table-no-border > tbody > tr > td, &.table-no-border > tfoot > tr > th, &.table-no-border > tfoot > tr > td{
        border:0;
        border-collapse: collapse;
    }
  }

  .resourceTables {
      border-collapse: separate !important;
      border-spacing:0 10px !important;
  }

// centered Radio control
.table-responsive{
    &.centerRadio input[type="radio"]{
        position: relative;
        margin-left: auto;
    }
}

.no-results {
  padding-left: 1em;
  font-style: italic;
  font-size: 18px;
}
  