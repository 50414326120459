//common classes for ACL

.b-1-gray {
  border: 1px solid #CCC;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 1em;
}

.m-2 {
  margin: 2em;
}

.mt-h {
  margin-top: .5em;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.mt-4 {
  margin-top: 4em;
}

.mt-8 {
  margin-top: 8em;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 1em;
}

.ml-2 {
  margin-left: 2em;
}

.mr-1 {
  margin-right: 1em;
}

.mr-2 {
  margin-right: 2em;
}

.mr-3 {
  margin-right: 3em;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-h {
  margin-bottom: .5em;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em !important;
}

.mb-3 {
  margin-bottom: 3em !important;
}

.mb-4 {
  margin-bottom: 4em;
}

.p-0 {
  padding: 0;
}

.p-h {
  padding: .5em;
}

.p-1 {
  padding: 1em;
}

.p-2 {
  padding: 2em;
}

.p-3 {
  padding: 3em;
}

.pt-1 {
  padding-top: 1em;
}

.pt-2 {
  padding-top: 2em;
}

.pt-3 {
  padding-top: 3em;
}

.pt-4 {
  padding-top: 4em;
}

.pt-10px {
  padding-top: 10px;
}

.pb-h {
  padding-bottom: .5em;
}

.pb-1 {
  padding-bottom: 1em;
}

.pb-2 {
  padding-bottom: 2em;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-h {
  padding-left: .5em !important;
}

.pl-1 {
  padding-left: 1em !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 1em !important;
}

.pr-2 {
  padding-right: 2em !important;
}

h1 {
  border-bottom: 1px solid $gray-lighter;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 30px;
}

h1,
h2,
h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
}

h1,
h1 a {
  color: #053359;
  font-size: 28px;
}

h2,
h2 a {
  color: #053359;
  font-size: 19px;
}

h2 {
  margin-bottom: 10px;
  font-weight: bold;
}

h3.main {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

h3,
h3 a {
  color: #2A2A2A;
  font-size: 17px;
  margin: 10px 0;
}

h4,
h4 a {
  color: #004f6a;
  font-size: 15px;
}

h5,
h5 a {
  color: #336688;
  font-size: 14px;
}
// use this class when you want your list items to have more padding between top and bottom of li
.ptb-h li {
  padding: .5em 0;
}

.ptb-1 li {
  padding: 1em 0;
}

.font-size-large {
  font-size: $font-size-large;
}

.font-size-larger {
  font-size: $font-size-h1;
}

.hidden {
  display: none;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}

.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline !important;
}

.block {
  display: block;
}

.table-cell {
  display: table-cell;
}

.align-middle {
  vertical-align: middle;
}

.normal {
  font-weight: 500;
}

.relative {
  position: relative;
}

.vtop {
  vertical-align: top;
}

body {
  color: #2A2A2A;
  background-color: #EEE;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 1.6em;
}

.acl-container {
  background-color: white;
  box-shadow: none;
  padding: 20px;
}

.p-3.states,
.p-3.events {
  text-align: center;
  background: #033458;
  color: #ffffff;
  margin-top: 10px;
  font-size: 16px;
}

.text-danger {
  color: #824442;
}

.pill-list {
  .btn {
    cursor: default;
    padding: 1em 2em;
  }

  .btn-default:hover {
    background: white;
  }

  .number {
    display: block;
    text-align: center;
    font-size: 30px;
    color: #053359;
    height: 40px;
  }
}

.paddingLeft20px {
  padding-left: 20px !important;
}

// Backgrounds
.background-gray-light {
  background-color: $gray-light;
}

.background-gray-lighter {
  background-color: $gray-lighter;
}

.background-gray-lightest {
  background-color: $gray-lightest;
}

// Font colors
.gray {
  color: $gray;
}

.gray-light {
  color: $gray-light;
}

.gray-lighter {
  color: $gray-lighter;
}

.gray-lightest {
  color: $gray-lightest;
}

// Borders
.border-bottom-gray {
  border-bottom: 1px solid $gray;
}

.border-bottom-gray-light {
  border-bottom: 1px solid $gray-light;
}

.border-bottom-gray-lighter {
  border-bottom: 1px solid $gray-lighter;
}

.border-top-gray-thick {
  border-top: 2px solid $gray;
}

// Breadcrumbs
.breadcrumb {
  padding: 8px 15px 8px 0;
  margin-bottom: 20px;
  list-style: none;
  background-color: transparent;
  border-radius: 4px;

  li {
    display: inline;
  }

  li + li:before {
    content: ">";
    padding: 0 5px;
    color: #ccc;
  }

  &.active {
    color: #555;
  }
}

ul.raw-list {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.inline-list {
  ul {
    list-style-type: none;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  li a {
    display: inline;
    padding: 8px;
  }

  li + li:before {
    content: "|";
    padding: 0 5px;
  }
}

.big-number-box {
  background-color: white;
  padding: 1em;
  margin-top: 1em;
}

.fa-icon-width-3x {
  min-width: 50px;
}

.jumbotron p {
  line-height: 1.6em;
}

.list-unstyled li {
  margin-bottom: 5px;
}

.pill-list .btn {
  padding: 1.3em;
  background: #f6f6f6;
  border: 1px solid #eeeeee;
  margin-right: 15px;
  font-size: 13px;
  color: #053359;
}

.pill-list .number {
  font-weight: bold;
}

.alert-info {
  background-color: #f3fafd;
}

.alert-status {
  border: 1px solid #cccccc;
}

small,
.small {
  line-height: 1.3em;
}

fieldset {
  margin: 20px 0px 30px 0px;
  color: #033458;

  legend {
    font-size: inherit;
  }
}

.reportRadiosFieldset {
  margin-bottom: 0px;

  legend {
    margin-bottom: 0px;
    border-bottom: 0;
  }
}

.fa.fa-angle-right {
  font-size: 1.3em;
  padding-right: .3em;
}


/* bread crumbs  */

.breadcrumb {
  > .active {
    color: #2A2A2A;
  }
}


/* place cursor on all a tags*/

a {
  cursor: pointer;
}

.align-top {
  td {
    vertical-align: top !important;
  }
}

.floatAndClearLeft {
  float: left;
  clear: left;
}

.clearBoth {
  clear: both;
}

/***** Loading spinner *****/


/* Absolute Center Spinner */

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}


/* Transparent Overlay */

.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}


/* :not(:required) hides these rules from IE9 and below */

.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}


/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/***** End loading spinner  *****/

// Styles for printing
@media print {
  .no-print {
    display: none;
  }
  .no-break {
    page-break-inside: avoid;
  }
  .print-only {
    display: block !important;
  }
  .print-only.line {
    display: inline !important;
  }
}

.print-only {
  display: none;
  word-break: break-all;
}


// Overview
.whiteFont {
  color: #F9F9F9;
}

.redFont {
  color: $brand-danger;
}

.greenFont {
  color: $brand-success;
}

.orangeFont {
  color: $brand-warning;
}

.darkRedFont {
  color: $brand-required;
}

.darkGreenFont {
  color: $brand-provided;
}

.LeftRightMargin0px {
  margin-left: 0px;
  margin-right: 0px;
}

.padding0pxWidth27pct {
  padding: 0px;
  width: 27%;
}

.padding0pxWidth5pct {
  padding: 0px;
  width: 5%;
}

.padding0pxWidth35pct {
  padding: 0px;
  width: 35%;
}

.padding0pxWidth7pct {
  padding: 0px;
  width: 7%;
}

.padding0pxWidth26pct {
  padding: 0px;
  width: 26%;
}

// Reports
.currentYearReportsBlock {
  display: inline-block;
  border: 1px solid black;
  border-color: $gray-light;
  padding: 1pc;
  max-width: 11pc;
  height: 15em;
  vertical-align: top;
}

.height3em {
  height: 3em;
}

.padding1em {
  padding: 1em;
}

//Variance Explanations
.panel-body-plain {
  background-color: white;
  padding: 10px 0px 0px 0px;
}

.show-whitespace{
  white-space: pre-line;
}

.border-none {
  border: none;
}

.background-highlighted {
  background-color: #ffff00;
}

.verticalResizeOnly {
  resize: vertical;
}

.td234-align-right td:nth-child(2),
.td234-align-right td:nth-child(3),
.td234-align-right td:nth-child(4) {
  text-align: right;
}

.td23456-align-right td:nth-child(2),
.td23456-align-right td:nth-child(3),
.td23456-align-right td:nth-child(4),
.td23456-align-right td:nth-child(5),
.td23456-align-right td:nth-child(6) {
  text-align: right;
}

// three select for dashboard
.btn-floating {
  display: inline-block;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: 0;
  background-color: #063557;
  border-radius: 50%;
  vertical-align: middle
}

.btn-floating.btn-success {
  background-color: #21874E;
}

.btn-floating.btn-large {
  width: 40px;
  height: 40px
}

.btn-floating.btn-large i {
  line-height: 40px
}

.btn-floating i {
  width: inherit;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 2rem;
  line-height: 40px
}

.insider {
  background: #ffffff;
  position: relative;
  border: 1px solid #ccc;
  /* for one child                          */
  &:first-child:nth-last-child(1) {
    border: 1px solid #ccc;
    padding: 1px;
  }

  &:first-child,
  &:nth-child(2) {
    border-right: 0;
  }

  &.selected {
    outline: none;
    background: #F9F9F9;
    color: #9B9B9B;

    .btn-floating,
    .box-in-box {
      background: #9B9B9B;
    }

    .single {
      border: 1px solid #ccc;
    }
  }

  .box-selection {
    width: 275px;
    border: 0;
    text-align: center;
    background: white;
    padding: 2em 1.5em 0 1.5em;

    &:focus {
      // outline: 0;
    }

    &:disabled {
      .box-in-box {
        display: block;
        padding: 1.5em 1em;
        color: white;
        background: #9B9B9B;
        text-transform: uppercase;
        min-height: 66px;
      }
    }

    .box-in-box {
      display: block;
      padding: 1.5em 1em;
      color: white;
      background: #377BB5;
      text-transform: uppercase;
      min-height: 66px;
    }

    p {
      padding-top: 1em;
    }

    hr {
      margin: 0;
    }

    .btn-floating {
      position: absolute;
      top: -1.5em;
      left: 45%;
    }

    li {
      text-align: left;
    }
  }
}

.insider-success {
  @extend .insider;
  background: #bbdcca;
  filter: opacity(67%);

  .box-selection {
    width: 275px;
    border: 0;
    text-align: center;
    background: #bbdcca;
    padding: 2em 1.5em 0 1.5em;

    &:focus {
      outline: 0;
    }
  }

  .box-in-box {
    display: block;
    padding: 1.5em 1em;
    color: white;
    background: #9B9B9B;
    text-transform: uppercase;
    min-height: 66px;
  }
}

.insider-disabled {
  @extend .insider-success;
  background: #F9F9F9;

  .box-selection {
    width: 275px;
    border: 0;
    text-align: center;
    background: #F9F9F9;
    padding: 2em 1.5em 0 1.5em;

    &:focus {
      outline: 0;
    }
  }
}

.accordionGroup {
  .panel-default > .panel-heading {
    background: #4A4A4A;
    border-radius: 0;

    span.badge {
      background: white;
      vertical-align: text-bottom;
      border-radius: 3px;
      margin-left: 10px;
      height: 20px;
    }
  }

  .panel-default > .panel-heading.collapsed {
    background: #11538D;
  }

  .panel-title {
    a {
      text-decoration: none;
      font-weight: 600;
      color: white;

      span.ico:after {
        content: "-";
        color: white;
        font-size: 20px;
        width: 18px;
        height: 26px;
        vertical-align: middle;
        display: inline-block;
      }

      &.collapsed span.ico:after {
        content: "+";
      }
    }
  }
}

#caregiverServicesHeading {
  text-decoration: none;
  font-weight: 600;
  color: white;
  cursor: default;
}

.gray-dash {
  padding: 1em;
  position: relative;
  background: #FAFAFA;
  border: 1px solid #ddd;
  min-height: 325px;

  h3 {
    border-bottom: 1px solid #ddd;
    color: #063557;
    font-size: 18px;
    font-weight: bold;
    padding-top: .5em;
    padding-bottom: .5em;
  }

  li {
    margin: 20px 0;
    border-left: 2px solid #F8BE49;
    padding-left: 10px;

    p {
      margin: 0;
    }

    strong {
      color: #335A7A;
    }
  }

  .btn-floating {
    display: inline-block;
    color: #fff;
    overflow: hidden;
    z-index: 1;
    padding: 0;
    background-color: #063557;
    border-radius: 50%;
    vertical-align: middle;
    position: absolute;
    top: -2em;
    left: 42%;
  }

  .btn-floating.btn-large {
    width: 50px;
    height: 50px
  }

  .btn-floating.btn-large i {
    line-height: 50px;
    font-size: 28px;
  }

  .btn-floating i {
    width: inherit;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 2rem;
    line-height: 40px
  }
  //added so that the buttons didn't span across box (flex)
  .btn-primary,
  .btn-default {
    width: 80%;
  }
}

.alert-combo {
  display: flex;
  margin-bottom: 20px; // alert info
  .alert-icon {
    min-width: 75px;
    background: #063557;
    color: white;
    position: relative;

    .btn-floating {
      display: inline-block;
      color: #fff;
      overflow: hidden;
      z-index: 1;
      padding: 0;
      background-color: #fff;
      border-radius: 50%;
      vertical-align: middle;
      position: absolute;
      top: 35%;
      left: 18px;
    }

    .btn-floating.btn-large {
      width: 40px;
      height: 40px
    }

    .btn-floating.btn-large i {
      line-height: 40px;
      font-size: 32px;
    }

    .btn-floating i {
      width: inherit;
      display: inline-block;
      text-align: center;
      color: #063557;
      font-size: 2rem;
      line-height: 40px
    }
  }

  .alert-info {
    background: #EEEEEE;
    border: #DDDDDD;
    border-radius: 0;
    color: black;
    margin-bottom: 0;

    .title {
      font-size: 20px;
      color: #063557;
    }
  }
}

.flyout {
  position: absolute;
  background: #FFF;
  z-index: 10;
  padding: 1em;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;

  input,
  label {
    display: block;
  }

  .checkbox {
    label {
      display: inline;
      font-weight: normal;
    }
  }
}
/* styles for '...' */
.elipText {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.6em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  padding-right: 1em;
  white-space: pre-wrap;
}

.elipText:before {
  /* points in the end */
  content: '...';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}

.elipText:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  width: 1em;
  /* set width and height */
  height: 1em;
  margin-top: 0.2em;
  background: white;
}
// Tooltips
.blue-tooltip + .tooltip > .tooltip-inner {
  background-color: #063557;
}

.blue-tooltip + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #063557;
}
// Grid
.row0 {
  margin: 0;
}
// select box
select {
  &.primary {
    background-color: #084475;
    border-color: #084475;
    color: white;
  }
}

.numberInputWidth {
  width: 11.5em;
}
// Black font
.blackFont {
  color: #000;
}

.tab-content {
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #CCC;
}

.line-break {
  white-space: pre-line;
}

.input-row {
  padding-bottom: 2em;
  &.last {
    padding-bottom: 3em;
  }
}