footer {
    background-color: #09528F;
    border-bottom: #F9BF3B 10px solid;
    color: #fff;
    margin-top: 45px;
    margin-bottom: 0 !important;
    max-width: 100% !important;
    padding: 0 0 75px 0!important;
    width: 100% !important;
    p.footerTitle {
        color: #EBC147;
        padding: 0;
        text-transform: uppercase;
        margin-bottom: 1em;
    }
    a {
        color: white;
    }
    a:hover {
        color: antiquewhite;
        text-decoration: none;
    }
    a:focus {
        color: white;
    }
    .footer-container {
        width: 90% !important;
        display: table;
        margin: 0 auto;
        padding: 30px 0;
        max-width: 1170px;
        width: 1170px;
    }
    .col-md-8 ul li {
        //background-image: url("assets/images/footer-divider.png");
        background-repeat: no-repeat;
        background-position: right center;
        &:first-child {
            a {
                padding-left: 0;
            }
        }
        &:last-child {
            background: none;
        }
        a {
            color: #EBC147;
            font-size: 0.9em;
            text-transform: uppercase;
            padding: 10px 15px;
        }
        a:hover,
        a:active {
            color: #fff;
            background-color: transparent;
            text-decoration: underline;
        }
    }
}