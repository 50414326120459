.jumbotron {
    &.home {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom:10px solid #EBC147;
        .bg {
            background-color: #033458;
            
            .container{
                background-image: url("/assets/images/home-hero@2x.png");
                /* Small Devices, Tablets */ 	
                @media only screen and (max-width : 768px){background-image: none;} 
                background-size: 100%;
                background-repeat: no-repeat;
                color:white;
                h1, p{font-weight: bold;}
                h1{
                    border-bottom:0;
                    font-size:52px;
                    text-transform:uppercase;
                    padding-bottom: 15px;
                }
                p{border-top:1px solid #EBC147;padding-top: 1em;}
                
    
            }
        }
        a{color:#EBC147;}
    }
}
.home{
    h2{
        font-size: 22px;
        text-transform: uppercase;
    }
}
.plain{
    background: white;
}