.background-gray-lighter{
    h2, h3, h4{font-size: 14px;font-weight:600; margin-top: 10px;display: inline-block;}
}
.bold{font-weight:bold !important;}
.text-secondary{color:$brand-secondary;}
.text-normal{font-weight:normal;}

// some various font sizes
.text-like-h2{font-size: $font-size-h2;}
.text-like-h3{font-size: $font-size-h3;}
.text-like-h4{font-size: $font-size-h4;}
.text-like-h5{font-size: $font-size-h5;}