.flex {
    display: flex;
} //defines container
.flex-baseline {
    justify-content: baseline;
}

.flex-between {
    justify-content: space-between;
} //defines alignment along main axis (items are evenly spaced)
.flex-center {
    justify-content: center;
} //defines alignment along main axis (items are centered along the line)
.flex-align-center {
    align-items: center;
} //This defines the default behavior for how flex items are laid out along the cross axis on the current line(items are centered in the cross-axis)
// some widths
.flex-fifty {
    flex-basis: 50%;
}

//solving the classic height + sticky bottom dilemma

/*Flex items*/

.flex-list {
    display: flex;
    flex-wrap: wrap;
}

.flex-list-item {
    display: flex;
    padding: 0.5em;
    width: 100%;
}

@media all and (min-width: 50em) {
    .flex-list-item {
        width: 50%;
    }
}

@media all and (min-width: 60em) {
    .flex-list-item {
        width: 33.33%;
    }
    // panel group
    .panel-group{
        .flex-list-item {
            width: 25%;
        }
    &.thirty{
        .flex-list-item {
            width: 33%;
        }
    }
    }

}

.flex-list-content {
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 100%;
}

.flex-list-content a{
    color:black;
}

.flex-list-content .description {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.flex-list-content .description:hover {
    overflow: visible;
    -webkit-line-clamp: 99;
    -ms-text-overflow: clip;
    text-overflow: clip;
  }

.flex-list-content .list-unstyled {
    flex: 1 0 auto;
} // special alignment negative margin
.negSevenM {
    margin-left:-7px;
    margin-right:-7px;
}

.flex-item-wrap {
    display: flex;
    min-width: 0;
}

.flex-1-2 {
    flex: 1 2;
}

.flex-column {
  flex-direction: column;
  }
